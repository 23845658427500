import React from "react";
import * as styles from "./socials.module.scss";

const Socials = ({ customStyles }) => {
    return (
        <div
            className={`d-flex justify-content-evenly justify-content-md-between align-items-center ${styles.socials} ${customStyles}`}
        >
            <a
                href="https://t.me/AlgoFiNetwork"
                alt="AlgoFi on Telegram"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg width="22.335" height="22.335" viewBox="0 0 22.335 22.335">
                    <path
                        d="M11.167,0A11.167,11.167,0,1,0,22.335,11.167,11.167,11.167,0,0,0,11.167,0Zm5.176,7.595c-.168,1.766-.9,6.051-1.265,8.029-.157.837-.465,1.117-.763,1.145-.649.06-1.141-.429-1.769-.84l-2.492-1.674c-1.1-.727-.388-1.126.241-1.779.164-.171,3.022-2.77,3.077-3.006a.229.229,0,0,0-.052-.2.258.258,0,0,0-.231-.022q-.148.034-4.71,3.113a2.12,2.12,0,0,1-1.211.447A7.97,7.97,0,0,1,5.431,12.4c-.7-.227-1.255-.348-1.207-.734q.038-.3.831-.617h0l6.513-2.805c3.1-1.29,3.746-1.514,4.166-1.521a.734.734,0,0,1,.433.13.47.47,0,0,1,.159.3A1.969,1.969,0,0,1,16.344,7.595Z"
                        fill="white"
                        fillRule="evenodd"
                    ></path>
                </svg>
            </a>
            <a
                href="https://github.com/alfogi"
                alt="AlgoFi on Github"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg width="22.443" height="21.855" viewBox="0 0 22.443 21.855">
                    <path
                        d="M19.77,10a11.206,11.206,0,0,0-3.541,21.837c.561.1.766-.244.766-.537s0-.973-.016-1.907c-3.116.677-3.775-1.5-3.775-1.5a2.955,2.955,0,0,0-1.244-1.639c-1.016-.7.078-.68.078-.68a2.355,2.355,0,0,1,1.717,1.153,2.382,2.382,0,0,0,3.259.932,2.418,2.418,0,0,1,.712-1.5c-2.488-.269-5.1-1.244-5.1-5.537a4.333,4.333,0,0,1,1.166-3.009,4,4,0,0,1,.11-2.955s.94-.3,3.079,1.147a10.655,10.655,0,0,1,5.612,0c2.149-1.451,3.076-1.147,3.076-1.147a3.984,3.984,0,0,1,.113,2.955,4.328,4.328,0,0,1,1.15,3.006c0,4.3-2.619,5.252-5.1,5.529a2.671,2.671,0,0,1,.76,2.074V31.3c0,.3.2.647.771.537A11.206,11.206,0,0,0,19.77,10Z"
                        transform="translate(-8.566 -10)"
                        fill="white"
                        fillRule="evenodd"
                    ></path>
                </svg>
            </a>
            <a
                href="https://medium.com/@AlgoFi"
                alt="AlgoFi on Medium"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg width="22.553" height="22.553" viewBox="0 0 22.553 22.553">
                    <path
                        d="M0,0V22.553H22.553V0ZM18.737,5.344,17.527,6.5a.353.353,0,0,0-.134.339v8.521a.353.353,0,0,0,.134.339l1.181,1.16v.255H12.767v-.255l1.224-1.188c.12-.12.12-.156.12-.339V8.448l-3.4,8.641h-.46L6.287,8.448v5.791a.8.8,0,0,0,.219.665L8.1,16.834v.255H3.585v-.255L5.177,14.9a.771.771,0,0,0,.205-.665v-6.7a.587.587,0,0,0-.191-.5l-1.415-1.7V5.089H8.169l3.4,7.446,2.985-7.446h4.187Z"
                        fill="white"
                    ></path>
                </svg>
            </a>
            <a
                href="https://twitter.com/AlgofiNetwork"
                alt="AlgoFi on Twitter"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg width="21.854" height="17.773" viewBox="0 0 21.854 17.773">
                    <path
                        d="M8.614,31.653A12.665,12.665,0,0,0,21.372,18.9c0-.2,0-.388-.014-.58a9.12,9.12,0,0,0,2.237-2.322,8.9,8.9,0,0,1-2.574.707,4.5,4.5,0,0,0,1.971-2.48,9,9,0,0,1-2.838,1.087,4.485,4.485,0,0,0-7.766,3.067A4.556,4.556,0,0,0,12.5,19.4a12.738,12.738,0,0,1-9.241-4.685A4.485,4.485,0,0,0,4.649,20.7a4.435,4.435,0,0,1-2.03-.562.479.479,0,0,0,0,.057,4.485,4.485,0,0,0,3.595,4.4,4.474,4.474,0,0,1-2.023.078,4.488,4.488,0,0,0,4.187,3.113A8.984,8.984,0,0,1,2.809,29.7a9.134,9.134,0,0,1-1.069-.062,12.7,12.7,0,0,0,6.883,2.014"
                        transform="translate(-1.74 -13.88)"
                        fill="white"
                    ></path>
                </svg>
            </a>
        </div>
    );
};

export default Socials;
