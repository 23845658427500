import React from "react";
import * as styles from "./footer.module.scss";
import Container from "../global/container";
import Socials from "../socials/socials";
import { Link } from "gatsby";

const Footer = () => {
    return (
        <footer className={styles.siteFooter}>
            <Container className="p-40 px-md-15 py-md-20">
                <div className="row align-items-center">
                    <div className="col-12 col-md-8 mb-20 mb-md-0 text-center text-md-left">
                        <ul className="list-style-none">
                            <li className="d-inline-block align-middle mr-15 mr-md-20">
                                <Link
                                    className="decoration-none text-white"
                                    to="/jobs"
                                >
                                    Jobs
                                </Link>
                            </li>
                            <li className="d-inline-block align-middle mr-15 mr-md-20">
                                <a
                                    className="decoration-none text-white"
                                    href="/whitepaper"
                                    target="_blank"
                                >
                                    Whitepaper
                                </a>
                            </li>
                            <li className="d-inline-block align-middle mr-15 mr-md-20">
                                <Link
                                    className="decoration-none text-white"
                                    to="/bug-bounty"
                                >
                                    Bug Bounty
                                </Link>
                            </li>
                            <li className="d-inline-block align-middle mr-15 mr-md-20">
                                <Link
                                    className="decoration-none text-white"
                                    to="/privacy"
                                >
                                    Privacy
                                </Link>
                            </li>
                            <li className="d-inline-block align-middle mr-15 mr-md-20">
                                <Link
                                    className="decoration-none text-white"
                                    to="/terms"
                                >
                                    Terms
                                </Link>
                            </li>
                            <li className="d-inline-block align-middle">
                                <Link
                                    className="decoration-none text-white"
                                    to="/disclaimer"
                                >
                                    Disclaimer
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 offset-lg-1">
                        <Socials></Socials>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
